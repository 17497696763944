import React, { useState } from 'react';
import Layout from '../components/Layout';
import UserProfile from '../components/UserProfile';


function UserProfilePage() {

  return (
    <>
    <Layout>
       <UserProfile/>
    </Layout>
    </>
  );
}

export default UserProfilePage;
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const baseUrl = 'https://api.rapidxpro.com'


const Lobbies = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLobbies, setFilteredLobbies] = useState([]);
  const [rawLobbies, setRawLobbies] = useState([]);
  const navigate = useNavigate()

  let token = localStorage.getItem('authToken')
  let Authorization = `Bearer ${token}`

  const fetchLobbies = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/lobbies/all`, {
        headers: {
          'Authorization': Authorization
        }
      }); // Replace 'YOUR_API_ENDPOINT' with the actual endpoint URL
      // if (!response.ok) {
      //   console.log("parul jaiswal")
      //   const data = await response.json();
      //   console.log(data)
      //   throw new Error('Failed to fetch lobbies');
      // }
      const data = await response.json();
      if(data.error == "Request is not authorized"){
        localStorage.removeItem('authToken')
        navigate('/')
      }
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    const getLobbies = async () => {
      const data = await fetchLobbies();
      setRawLobbies(data);
      setFilteredLobbies(data);
    };

    getLobbies();
  }, []);

  useEffect(() => {
    const filtered = rawLobbies.filter(lobby => 
      Object.values(lobby).some(value => 
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredLobbies(filtered);
    setCurrentPage(1); // Reset to the first page on search
  }, [searchTerm, rawLobbies]);

  const totalItems = filteredLobbies.length;
  const lobbies = filteredLobbies.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page on items per page change
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleDelete = async (item) => {
    console.log('handle delete')
    try{
      const response = await axios.delete(`${baseUrl}/api/lobbies/delete/${item._id}`, {
        headers: {
          'Authorization': Authorization
        }
      });

      let data = response.json();

     
      if(data.error == "Request is not authorized"){
        localStorage.removeItem('authToken')
        navigate('/')
      }
      alert("Lobby deleted succefully.")
      fetchLobbies()
      console.log(response)
    }catch(err){
      console.log(err.response.data.error)
      alert(err.response.data.error)
    }
  }

  return (
    <div className="bg-white rounded shadow p-4 w-full ">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold mb-4">Lobbies</h2>
        <Link to='/addlobbies'><button className="bg-blue-500 text-white px-4 py-2 rounded">Add New</button></Link>
      </div>
      <div className="flex justify-between mb-4">
        <div>
          <label>
            Show 
            <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="ml-2 border">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select> entries
          </label>
        </div>
        <div>
          <label>
            Search:
            <input 
              type="text" 
              value={searchTerm} 
              onChange={handleSearchChange} 
              className="ml-2 border"
            />
          </label>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Created At</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Game Type</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Game Mode</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">No Of Players</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Entry Fee</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Commission %</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Win Amount</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-sm  text-black font-bold tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {lobbies.map((lobby) => (
              <tr key={lobby.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(lobby.createdAt)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.gameType}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.gameMode}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.noOfPlayers}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.entryFee}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.commissionPercentage}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.winAmount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><div className='px-2 py-1 text-white bg-green-500 rounded-md w-[60px] text-center'>{lobby.status}</div></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className='actions flex items-center justify-start space-x-1'>
                <Link to={`/editlobby/${lobby._id}`}><button className="text-red-600 hover:text-red-900 ml-4">
                  <div className="bg-[#3c8dbc] px-1 py-0 rounded">
                    <i className="fa-solid fa-pen-to-square text-white"></i>
                  </div>
                </button></Link>
                <button onClick={() => handleDelete(lobby)} className="text-red-600 hover:text-red-900 ml-4">
                  <div className="bg-[#3c8dbc] px-1 py-0 rounded">
                    <i className="fa-solid fa-trash text-white"></i>
                  </div>
                </button>
                </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-between items-center">
        <div>Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} entries</div>
        <div>
          <button 
            className="px-4 py-2 border mr-2" 
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button 
            className="px-4 py-2 border" 
            onClick={handleNextPage}
            disabled={currentPage * itemsPerPage >= totalItems}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};



export default Lobbies;

// components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
// import { isAuthenticated } from '../utils/auth';

const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    return !!token; // Returns true if token exists, false otherwise
};

const PrivateRoute = ({ children }) => {
    return isAuthenticated() ? children : <Navigate to="/" />;
};

export default PrivateRoute;

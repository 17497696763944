import React, { useState } from 'react';
import Layout from '../components/Layout';
import GameReport from '../components/GameReport';


function GamePage() {


  return (
    <>
  
    <Layout>
       <GameReport/>
    </Layout>
    </>
  );
}

export default GamePage;
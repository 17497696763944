import React, { useState } from 'react';
import Dashboard from '../components/Dashboard';
import Layout from '../components/Layout';


function Dashboardpage() {

  return (
    <>
    <Layout>
       <Dashboard />
    </Layout>
    </>
  );
}

export default Dashboardpage;
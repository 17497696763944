import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com';

const WithDrewReq = () => {
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const itemsPerPage = 10; // You can adjust this value as needed
  const totalItems = requests.length;
  const navigate = useNavigate();

  let token = localStorage.getItem('authToken');
  let Authorization = `Bearer ${token}`;

  const lobbies = requests.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/api/withdraw/request/all`, {
          headers: {
            'Authorization': Authorization
          }
        }); // Replace with your actual API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch game data');
        }
        const data = await response.json();
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
        // console.log(data)
        setRequests(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleAccept = async (request) => {
    console.log(request);
    try {
      // Call minusCoins API
      const response = await fetch(`${baseUrl}/api/withdraw/request/accept/${request._id}`, {
        method: 'PUT',
        headers: {
          'Authorization': Authorization,
          'Content-Type': 'application/json'
        }
      });
      // Remove the request from the list and add to history
      if (response.ok) {
        let data = response.data
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
        setRequests(requests.filter(req => req !== request));
        navigate('/requests'); // Redirect after successful operation
      } else {
        console.error('Failed to accept the request');
      }
    } catch (error) {
      console.error('Error accepting request:', error);
    }
  };

  const handleReject = (request) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const submitRejectReason = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/withdraw/request/reject/${selectedRequest._id}`, {
        method: 'PUT',
        headers: {
          'Authorization': Authorization,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rejectReason: rejectReason
        })
      });

      if (response.ok) {
        let data = response.data
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
        setRequests(requests.filter(req => req !== selectedRequest));
        setShowModal(false);
        setRejectReason('');
        navigate('/requests'); // Redirect after successful operation
      } else {
        console.error('Failed to reject the request');
      }
    } catch (error) {
      console.error('Error rejecting request:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bg-white rounded shadow p-4 w-full ">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Withdraw Requests</h2>
      </div>
      <div className="overflow-x-auto ">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Request At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UPI id</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {lobbies && console.log(lobbies)}
            {lobbies.map((lobby) => (
              <tr key={lobby.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(lobby.requestAt)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.userName}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.upiId}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.amount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button onClick={() => handleAccept(lobby)} className="bg-yellow-500 text-white px-2 py-1 rounded mr-2">Accept</button>
                  <button onClick={() => handleReject(lobby)} className="bg-red-500 text-white px-2 py-1 rounded">Reject</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <div>Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} entries</div>
        <div>
          <button
            className="px-4 py-2 border mr-2"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 border"
            onClick={handleNextPage}
            disabled={currentPage * itemsPerPage >= totalItems}
          >
            Next
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded">
            <h2 className="text-xl font-bold mb-4">Reason for Rejection</h2>
            <textarea
              className="w-[400px] p-2 border"
              value={rejectReason}
              onChange={(e) => setRejectReason(e.target.value)}
              rows="4"
            ></textarea>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded"
                onClick={submitRejectReason}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WithDrewReq;

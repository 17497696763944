import React, { useEffect, useState } from 'react';
import OrgChart from '@dabeng/react-orgchart';
import { useNavigate } from 'react-router-dom';
// import '@dabeng/react-orgchart/dist/styles/orgchart.css';

const OrgChartTree = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem("authToken") ? localStorage.getItem("authToken") : false;
  const Authorization = `Bearer ${token}`;

  const getReferrals = async () => {
    try {
      const response = await fetch('https://api.rapidxpro.com/api/superAdmin/referralTree', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': Authorization
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      
      if (responseData.error === "Request is not authorized") {
        localStorage.removeItem('authToken');
        navigate('/');
        return;
      }

      // Transform the data to set "rapidxpro" as the root node
      const transformedData = {
        phoneNumber: 'rapidxpro',
        name: 'rapidxpro',
        referralCount: 0,
        children: responseData // The API data becomes children of "rapidxpro"
      };

      setData(transformedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getReferrals();
  }, []);

  return (
    <div className="orgchart-container max-h-[400px]  scroll-auto">
      <OrgChart
        className="border-none bg-white overflow-auto"
        datasource={data}
        chartClass="myChart"
        style={{ backgroundImg: 'none' }}
      />
    </div>
  );
};

export default OrgChartTree;

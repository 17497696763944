import React, { useState } from 'react';
import Layout from '../components/Layout';
import AddLobbies from '../components/AddLobbies';


function AddLobbiesPage() {

  return (
    <>
    <Layout>
       <AddLobbies/>
    </Layout>
    </>
  );
}

export default AddLobbiesPage;
import React, { useState } from 'react';
import Layout from '../components/Layout';
import RapidCoins from '../components/RapidCoins';


function RapidCoinsPage() {


  return (
    <>
    
    <div className='max-w-full overflow-hidden'>
    <Layout>
       <RapidCoins/>
    </Layout>
    </div>
    </>
  );
}

export default RapidCoinsPage;
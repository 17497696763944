import React, {useState} from 'react'

const Setting = () => {

  const [isChecked, setIsChecked] = useState(false);
  const [isDepositCheck, setIsDepositCheck] = useState(false)
  const [commissionType, setCommissionType] = useState('Percentage'); 

  const handleCommissionTypeChange = (event) => {
    setCommissionType(event.target.value);
  }; 

  const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
  };

  const handleDepositCheckboxChange = (event) => {
    setIsDepositCheck(event.target.checked);
  };

  return (
    <div className="container mx-auto p-6">
    <h1 className="text-2xl font-semibold mb-4">Settings</h1>
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-lg font-medium mb-4">GST/TDS Info</h2>
      <div className="mb-10 flex items-center justify-between">
      <div className='flex-col gap-4'>
          <p className='text-sm font-bold mb-4'>Is GST/TDS</p>
          <div className='flex items-center'>
          <input
              id="checked-checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />          
          <label htmlFor="checked-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is GST/TDS</label>
          </div>
      </div>
    
        {isChecked && (
                <div>
                    <label className="block text-sm font-bold mb-1">GST/TDS Percentage</label>
                    <input
                        type="number" defaultValue={2}
                        className="w-[50vw] mr-10 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>
            )}
      </div>
      <hr className='mb-3'></hr>

      <h2 className="text-lg font-bold mb-4">Refer & Earn</h2>
      <div className='flex items-center justify-between'>
        <div className='w-1/2'>
            <h2 className='text-[18px] font-medium text-gray-700 mb-3'>Referral One Time on First Deposit</h2>
            <div className='flex items-center gap-[30px]'>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Commission Type</label>
                <select  value={commissionType} onChange={handleCommissionTypeChange} className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500">
                  <option>Percentage</option>
                  <option>Fixed</option>
                </select>
            </div>
            <div className="mb-4 ">
              
              {commissionType === 'Percentage' ? (
                        <label className="block text-sm font-medium text-gray-700 mb-1">Commission Percentage</label>
                    ) : (
                        <label className="block text-sm font-medium text-gray-700 mb-1">Fixed Amount</label>
                    )}
              <input defaultValue={2} type="number" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
            </div>
            </div>
        </div>

        <div className='w-1/2'>
            <h2 className='text-[18px] font-medium text-gray-700 mb-3'>Referral Life Time on First Winning</h2>
            
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Commission Percentage</label>
              <input defaultValue={2} type="number" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
            </div>
       
        </div>

      </div>
      <hr className='my-3'></hr>


      <h2 className="text-lg font-bold mb-4">Bonus Info</h2>
      <div className='flex  justify-between'>
        <div className='w-1/2'>
            <h2 className='text-[18px] font-medium text-gray-700 mb-3'>Signup Bonus</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">Signup Bonus</label>
              <input type="number" defaultValue={2000} className=" px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
            </div>
        </div>

        <div className='w-1/2'>
            <h2 className="text-lg font-medium mb-4">Deposit Bonus</h2>
           
            <div className='flex gap-10 items-start'>
               <div className='w-1/3'>
               <h1>Deposit Bonus Status</h1>
                <div className=" mb-4 flex items-center gap-3">
                  <input type="checkbox" checked={isDepositCheck} onChange={handleDepositCheckboxChange} className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500" />
                  <label className="block text-sm font-medium text-gray-700 mb-1">Deposit Bonus Status</label>
                </div>
               </div>

                {isDepositCheck && (
                  <div>
                    <h1 className='mb-4'>Range Wise Bonus Percentages</h1>
                    <div className="grid grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-bold text-gray-700 mb-1">Min</label>
                      <input type="number" defaultValue={0} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={201} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={501} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={1001} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700 mb-1">Max</label>
                      <input type="number" defaultValue={200} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={500} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={1000} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={5000} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-gray-700 mb-1">Percentage</label>
                      <input type="number" defaultValue={5} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={5} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={5} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                      <input type="number" defaultValue={5} className="w-[100px] mb-3 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500" />
                    </div>
                    </div>
                  </div>
                 )}

            </div>
            

           
           
       
        </div>
      </div>


      <button
            type="submit"
            className="px-4 bg-blue-500 text-white py-2 rounded-md hover:bg-blue-700 transition-colors mr-5"
          >
            save
      </button>

      
      </div>


     
    
  </div>
  )
}

export default Setting
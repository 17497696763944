import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com'



  function UserLogs() {

    const [usersData, setUsersData] = useState([])
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const totalItems = usersData.length;
    const users = usersData.slice((currentPage-1)*itemsPerPage, (currentPage*itemsPerPage))

    let token = localStorage.getItem('authToken')
    let Authorization = `Bearer ${token}`

    useEffect(() => {
      const fetchUserLogs = async () => {
        try {
          setLoading(true);
          const response = await fetch(`${baseUrl}/api/reports/userLogs/all`  , {
            headers: {
              'Authorization': Authorization
            }
          }); // Replace with your actual API endpoint
          if (!response.ok) {
            throw new Error('Failed to fetch game data');
          }
          const data = await response.json();
          console.log(data)
          if(data.error === "Request is not authorized"){
            localStorage.removeItem('authToken')
            navigate('/')
          }
          setUsersData(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchUserLogs();
    }, []);
  
    const formatDate = (dateString) => {
      const date = new Date(dateString);
    
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
    
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
    
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handlePreviousPage = () => {
      if(currentPage >1){
        setCurrentPage(currentPage-1)
      }
    }

    const handleNextPage = () => {
      if(currentPage*itemsPerPage < totalItems){
        setCurrentPage(currentPage+1)
      }
    }

    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <div className="bg-white p-4 rounded-lg shadow-md overflow-x-auto">
        <h2 className="text-2xl font-semibold mb-4">User Login Logs Report</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logged in At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">IP Address</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Agent</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(user.loggedAt)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.ipAddress}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.userAgent}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-between">
          <span>Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} entries</span>
          <div className="flex space-x-2">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={handlePreviousPage}>Previous</button>
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={handleNextPage}>Next</button>
          </div>
        </div>
      </div>
    );
  }
  
  export default UserLogs;
  
  
  
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com'


const GameReport = () => {
  const [gamesData, setGamesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const navigate = useNavigate()

  let token = localStorage.getItem('authToken')
  let Authorization = `Bearer ${token}`

  useEffect(() => {
    const fetchGamesData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/api/reports/games/all`  , {
          headers: {
            'Authorization': Authorization
          }
        }); // Replace with your actual API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch game data');
        }
        const data = await response.json();
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
        setGamesData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGamesData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  const totalItems = gamesData.length;
  const games = gamesData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen flex">
      <div className="flex-1 p-6 bg-gray-100">
        <header className="flex items-center justify-between p-4 bg-white shadow-md">
          <h1 className="text-2xl font-semibold">Game Report</h1>
          <div className="flex items-center"></div>
        </header>

        <main className="mt-6">
          <section className="bg-white p-4 rounded-lg shadow-md">
            <div className="mb-10 flex items-center justify-normal gap-3">
              <label className="block text-[18px] font-medium text-gray-700">From:</label>
              <input type="date" className="mt-1 p-2 border border-gray-300 rounded-md" />
              <label className="block text-[18px] font-medium text-gray-700">To:</label>
              <input type="date" className="mt-1 p-2 border border-gray-300 rounded-md" />
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Search</button>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Clear</button>
            </div>

            <div className="mt-6 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Played At</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Id</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Game Type</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Game Mode</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Max Players</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Played Players</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Entry Fee</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Game Duration</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Commission %</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {games.map((game, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(game.playedAt)}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.roomId}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.gameType}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.gameMode}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.maxPlayers}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.noOfPlayers}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.entryFee}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.gameDuration}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{game.commission}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-4 flex justify-between">
                <span>Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} entries</span>
                <div className="flex space-x-2">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={handlePreviousPage}>Previous</button>
                  <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={handleNextPage}>Next</button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default GameReport;

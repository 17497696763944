import React, { useState } from 'react';
import Layout from '../components/Layout';
import RejectedWR from '../components/RejectedWR';


function RejectedPage() {


  return (
    <>
  
    <Layout>
       <RejectedWR/>
    </Layout>
    </>
  );
}

export default RejectedPage;
import React, { useState } from 'react';
import Dashboardpage from './pages/Dashboardpage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UserPage from './pages/UserPage';
import LobbiesPage from './pages/LobbiesPage';
import ReqPage from './pages/ReqPage';
import GamePage from './pages/GamePage';
import UserLogsPage from './pages/UserLogsPage';
import SettingPage from './pages/SettingPage';
import LoginPage from './pages/LoginPage';
import AddLobbiesPage from './pages/AddLobbiesPage';
import EditLobbyPage from './pages/EditLobbyPage';
import UserProfilePage from './pages/UserProfilePage';
import PrivateRoute from './components/PrivateRoute';
import AcceptedPage from './pages/AcceptedPage';
import RejectedPage from './pages/RejectedPage';
import RapidCoinsPage from './pages/RapidCoinsPage';
import TreeChartPage from './pages/TreeChartPage';
import LotteryNumberPage from './pages/LotteryNumberPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'

function App() {
  

  return (
    <>
   
     {/* <Dashboardpage/> */}
     <BrowserRouter>
       <Routes>
          <Route path='/' element={<LoginPage/>}/>
          <Route path='/dashboard' element={<PrivateRoute><Dashboardpage/></PrivateRoute>}/>
          <Route path='/users' element={<PrivateRoute><UserPage/></PrivateRoute>}/>       
          <Route path='/userProfile/:id' element={<PrivateRoute><UserProfilePage/></PrivateRoute>}/>       
          <Route path="/lobbies" element={<PrivateRoute><LobbiesPage/></PrivateRoute>} />
          <Route path='/addlobbies' element={<PrivateRoute><AddLobbiesPage/></PrivateRoute>}/>
          <Route path='/editlobby/:id' element={<PrivateRoute><EditLobbyPage/></PrivateRoute>}/>
          <Route path="/requests" element={<PrivateRoute><ReqPage/></PrivateRoute>} />
          <Route path="/acceptedRequests" element={<PrivateRoute><AcceptedPage/></PrivateRoute>} />
          <Route path="/rejectedRequests" element={<PrivateRoute><RejectedPage/></PrivateRoute>} />
          <Route path="/game" element={<PrivateRoute><GamePage/></PrivateRoute>} />
          <Route path="/user-logs" element={<PrivateRoute><UserLogsPage/></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><SettingPage/></PrivateRoute>} />
          <Route path="/rapidCoins" element={<PrivateRoute><RapidCoinsPage/></PrivateRoute>} />
          <Route path="/lotteryNumber" element={<PrivateRoute><LotteryNumberPage/></PrivateRoute>} />
          <Route path='/treeChart' element={<PrivateRoute><TreeChartPage/></PrivateRoute>}/>


       
       </Routes>
       <ToastContainer theme='dark'/>
     </BrowserRouter>
    
    </>
  );
}

export default App;
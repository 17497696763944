import React, { useState, useEffect, useRef } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

function Navbar({ isOpen, toggleSidebar }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate()
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    // Add your logout logic here
    localStorage.removeItem('authToken')
    navigate('/')
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <>
      <button
        onClick={toggleSidebar}
        className={`fixed block left-3 ${isOpen ? 'top-20' : 'top-5'}  focus:outline-none md:hidden z-50`}
        style={{ left: isOpen ? '220px' : '14px' }}
      >
        {isOpen ? (
          <XMarkIcon className="w-6 h-6 text-white transition duration-300 ease-in-out z-50" />
        ) : (
          <Bars3Icon className="w-6 h-6 text-white transition duration-300 ease-in-out z-50" />
        )}
      </button>

      <div className={`flex max-w-full justify-between items-center fixed top-0 w-full z-40 bg-[#3c8dbc] p-4`}>
        <div className="text-white text-lg font-bold pl-8">Rapidxpro</div>
        <div className="flex items-center relative">
          <div className="text-white mr-2">Admin</div>
          <div className="bg-white p-1 rounded-full" onClick={handleDropdownToggle}>
            <UserCircleIcon className="h-6 w-6 text-blue-700 cursor-pointer" />
          </div>

          {dropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute right-0 mt-[90px] w-42 bg-white rounded-md shadow-lg py-1"
            >
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Navbar;

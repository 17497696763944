import React, { useState } from 'react';
import Layout from '../components/Layout';
import Setting from '../components/Setting';


function SettingPage() {


  return (
    <>
  
    <Layout>
       <Setting/>
    </Layout>
    </>
  );
}

export default SettingPage;
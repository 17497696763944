import React, { useState } from 'react';
import { Link, useHistory, useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com'

const AddLobbies = () => {
  const [gameType, setGameType] = useState('Public');
  const [gameMode, setGameMode] = useState('Quick');
  const [maxPlayers, setMaxPlayers] = useState('');
  const [entryFee, setEntryFee] = useState('');
  const [commissionPercentage, setCommissionPercentage] = useState('');
  const [rankWinAmount, setRankWinAmount] = useState('');
  let token = localStorage.getItem('authToken')
  let Authorization = `Bearer ${token}`
  const navigate = useNavigate();

  const addLobby = async (lobbyData) => {
    try {
      const response = await fetch(`${baseUrl}/api/lobbies/create`, { // Replace with your actual API endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': Authorization
        },
        body: JSON.stringify(lobbyData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add lobby');
      }
  
      const data = await response.json();
      console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const lobbyData = {
      gameType,
      gameMode,
      noOfPlayers: Number(maxPlayers),
      entryFee: Number(entryFee),
      commissionPercentage: Number(commissionPercentage),
      winAmount: Number(rankWinAmount),
    };

    console.log(lobbyData)

    const result = await addLobby(lobbyData);

    


    if (result) {
      // Redirect to the lobbies list page on successful addition
      navigate('/lobbies');
    } else {
      // Handle error case (e.g., show an error message)
      alert('Failed to add lobby. Please try again.');
    }
  };

  return (
    <div className="bg-white rounded shadow p-4 w-full">
      <div>
        <h2 className="text-2xl font-bold mb-6">Add Lobbies</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="game-type">Game Type</label>
            <select
              id="game-type"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={gameType}
              onChange={(e) => setGameType(e.target.value)}
            >
              <option>Public</option>
              <option>Private</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="game-mode">Game Mode</label>
            <select
              id="game-mode"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={gameMode}
              onChange={(e) => setGameMode(e.target.value)}
            >
              <option>Quick</option>
              <option>Timed (600s)</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="max-players">Max Players</label>
            <input
              type="number"
              id="max-players"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Max Players"
              value={maxPlayers}
              onChange={(e) => setMaxPlayers(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="entry-fee">Entry Fee</label>
            <input
              type="number"
              id="entry-fee"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Entry Fee"
              value={entryFee}
              onChange={(e) => setEntryFee(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="commission-percentage">Commission Percentage</label>
            <input
              type="number"
              id="commission-percentage"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Commission Percentage"
              value={commissionPercentage}
              onChange={(e) => setCommissionPercentage(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 mb-2" htmlFor="rank-win-amount">Rank Wise Win Amount</label>
            <input
              type="number"
              id="rank-win-amount"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Rank Wise Win Amount"
              value={rankWinAmount}
              onChange={(e) => setRankWinAmount(e.target.value)}
            />
          </div>
          <div className="flex justify-end space-x-4">
            <Link to='/lobbies'><button
              type="button"
              className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-200"
            >
              Back
            </button></Link>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}


export default AddLobbies;

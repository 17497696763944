import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com'


const AcceptedWR = () => {

  const [requests, setRequests] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10; // You can adjust this value as needed
  const totalItems = requests.length;
  const navigate = useNavigate()

  let token = localStorage.getItem('rapidxUser')
  let Authorization = `Bearer ${token}`

  const lobbies = requests.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/api/withdraw/request/accepted`  , {
          headers: {
            'Authorization': Authorization
          }
        }); // Replace with your actual API endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch game data');
        }
        const data = await response.json();
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('rapidxUser')
          navigate('/')
        }
        // console.log(data)
        setRequests(data);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

 

  return (
    <div className="bg-white rounded shadow p-4 w-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Withdraw Requests</h2>
      </div>
      <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Request At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">UPI id</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {lobbies && console.log(lobbies)}
            {lobbies.map((lobby) => (
              <tr key={lobby.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(lobby.requestAt)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.userName}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.upiId}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lobby.amount}</td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <p className="bg-green-500 max-w-[80px] cursor-default text-white px-2 py-1 rounded mr-2">Accepted</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
        <div className="mt-4 flex justify-between items-center">
        <div>Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} entries</div>
        <div>
          <button 
            className="px-4 py-2 border mr-2" 
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button 
            className="px-4 py-2 border" 
            onClick={handleNextPage}
            disabled={currentPage * itemsPerPage >= totalItems}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default AcceptedWR

import React, { useState } from 'react';
import Layout from '../components/Layout';
import EditLobby from '../components/EditLobby';


function EditLobbyPage() {

  return (
    <>
    <Layout>
       <EditLobby/>
    </Layout>
    </>
  );
}

export default EditLobbyPage;
import React, { useState } from 'react';
import Users from '../components/Users';
import Layout from '../components/Layout';


function UserPage() {


  return (
    <>
    
    <div className='max-w-full overflow-hidden'>
    <Layout>
       <Users/>
    </Layout>
    </div>
    </>
  );
}

export default UserPage;
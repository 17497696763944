import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

function Sidebar({ isOpen, toggleSidebar }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdown1Open, setIsDropdown1Open] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdown1 = () => {
    setIsDropdown1Open(!isDropdown1Open);
  };

  return (
    <>
      {/* <button
        onClick={toggleSidebar}
        className={`fixed block left-3  ${ isOpen ? 'top-5' : 'top-5'} focus:outline-none md:hidden z-50`}
        // style={{ left: isOpen ? '220px' : '14px'  }}
      >
        {isOpen ? (
          <XMarkIcon className="w-6 h-6 text-white transition duration-300 ease-in-out z-50" />
        ) : (
          <Bars3Icon className="w-6 h-6 text-white transition duration-300 ease-in-out z-50" />
        )} 
      </button>
       */}
      <div className={`fixed z-50 h-[100vh] pt-20 top-0 left-0 w-64 bg-[#222d32] text-white transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}>
        {/* <div className="flex items-center justify-between p-4 md:hidden">
          <span className="text-xl font-bold">MahakalLudo</span>
        </div> */}
        {/* <div className="p-4 text-center font-bold text-xl hidden md:block">MahakalLudo</div> */}
        <nav className="flex-1">
          <ul>
          <Link to='/dashboard'>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer" >
              <div className='flex items-center gap-2'>
                <i className="fa-solid fa-gauge"></i>
                <span>Dashboard</span>
              </div>
            </li>
          </Link>
          <Link to='/users'>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer">
              <div className='flex items-center gap-2'>
                <i class="fa-solid fa-users"></i>
                 <span>Users</span>
              </div>
            </li>
          </Link>
          <Link to='/lobbies'>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer">
              <div className='flex items-center gap-2'>
                 <i class="fa-brands fa-dashcube"></i>
                 <span>Lobbies</span>
              </div>
            </li>
          </Link>
         
          <li className="p-4 hover:bg-[#1e282c] cursor-pointer" >
              <div className='flex items-center gap-2' onClick={toggleDropdown}>
              <i class="fa-solid fa-clipboard"></i>
              <span>Withdraw Requests</span>
              </div>
              {isDropdownOpen && (
                <ul className="bg-[#1e282c] mt-2 text-[14px]">
                  <Link to='/requests'><li className="p-2 px-4 hover:bg-[#1e282c] hover:color-[#8aa4af]">
                    Requests
                  </li></Link>
                  <Link to='/acceptedRequests'><li className="p-2 px-4 hover:bg-[#1e282c]">
                     Accepted
                  </li></Link>
                  <Link to='/rejectedRequests'><li className="p-2 px-4 hover:bg-[#1e282c]">
                     Rejected
                  </li></Link>
                </ul>
              )}
            </li>
            <Link to='/lotteryNumber'>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer">
              <div className='flex items-center gap-2'>
               <i className="fa-solid fa-coins"></i>
                 <span>Lottery Number</span>
              </div>
            </li>
          </Link>
            <Link to='/rapidCoins'>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer">
              <div className='flex items-center gap-2'>
               <i className="fa-solid fa-coins"></i>
                 <span>Rapid Coins</span>
              </div>
            </li>
          </Link>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer" onClick={toggleDropdown1}>
              <div className='flex items-center gap-2'>
              <i class="fa-solid fa-clipboard"></i>
              <span>Reports</span>
              </div>
              {isDropdown1Open && (
                <ul className="bg-[#1e282c] mt-2 text-[14px]">
                  <Link to='/game'><li className="p-4 hover:bg-[#1e282c] hover:color-[#8aa4af]">
                    Game
                  </li></Link>
                  <Link to='/user-logs'><li className="p-4 hover:bg-[#1e282c]">
                     User Login Logs
                  </li></Link>
                </ul>
              )}
            </li>
            <Link to='/treeChart'>
            <li className="p-4 hover:bg-[#1e282c] cursor-pointer">
              <div className='flex items-center gap-2'>
               <i className="fa-solid fa-coins"></i>
                 <span>Referral Tree</span>
              </div>
            </li>
          </Link>
            <Link to='/settings'><li className="p-4 hover:bg-[#1e282c]">
              <div className='flex items-center gap-2'>
              <i class="fa-solid fa-gear"></i>
              <span>Settings</span>
              </div>
            </li></Link>
          </ul>
        </nav>
        
      </div>
    </>
  );
}

export default Sidebar;


import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LotteryComponent = () => {
  const [selection, setSelection] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [winNumber, setWinNumber] = useState('');
  const [timeSlots, setTimeSlots] = useState([{ startTime: '', endTime: '' }]); // Time slots
  const notifySuccess = (msg) => toast.success(msg)
  const notifyError = (msg) => toast.error(msg)


  const apiUrl = 'https://rapidgame.rapidxpro.com/rapidgameapi/api/v1/lottery/daily/gameresult/settings';
  const apiKey = 'a467e5-d52d15-38533b-a5c5ca-5c1561';

  // Fetch the existing game result settings
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
          }
        });
        const result = await response.json();
        if (result.Data.StatusCode === 200) {
          const { GameResultTimes, GameResultMode, GameResultDifficultyLevel } = result.Data.Content;
          setTimeSlots(GameResultTimes.map(slot => ({
            startTime: slot.StartTime,
            endTime: slot.EndTime
          })));
          setSelection(GameResultMode);
          setDifficulty(GameResultDifficultyLevel);
        } else {
          notifyError('Failed to fetch settings.');
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        notifyError('Error fetching settings.');
      }
    };

    fetchSettings();
  }, []);

  // Handle time slot input changes
  const handleTimeSlotChange = (index, field, value) => {
    const updatedSlots = [...timeSlots];
    updatedSlots[index][field] = value;
    setTimeSlots(updatedSlots);
  };

  // Add a new time slot
  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { startTime: '', endTime: '' }]);
  };

  // Remove a time slot
  const removeTimeSlot = (index) => {
    const updatedSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(updatedSlots);
  };

  // Time format validation (HH:mm:ss)
  const isValidTimeFormat = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()

    const apiUrl = 'https://rapidgame.rapidxpro.com/rapidgameapi/api/v1/lottery/daily/gameresult/settings/update';
    const apiKey = 'a467e5-d52d15-38533b-a5c5ca-5c1561';

    // Validate all time slots before submission
    for (let slot of timeSlots) {
      if (!isValidTimeFormat(slot.startTime) || !isValidTimeFormat(slot.endTime)) {
        notifyError('Invalid time format. Please use hh:mm:ss.');
        return;
      }
    }

    let data = {
      Data: {
        GameResultTimes: timeSlots.map(slot => ({
          StartTime: slot.startTime,
          EndTime: slot.endTime
        })),
        GameResultMode: selection === 'manual' ? 'manual' : 'automatic',
        GameResultDifficultyLevel: difficulty || 'easy',
        WinNumber: winNumber || ''
      }
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      console.log('API Response:', result);
      // alert('Settings updated successfully!');
      if(result.Data.StatusCode !== 200){
          notifyError(result.Data.Message)
          return
      }
      notifySuccess(result.Data.Message)
    } catch (error) {
      console.error('Error in API call:', error);
      alert('Failed to update settings');
    }

  };

  return (
    <div>
      <h3 className='text-[28px] font-bold'>Select Option for Lottery Number</h3>

      <div className='flex items-center justify-around mt-10'>
        <label className='text-[25px]'>
          <input
            type="radio"
            value="manual"
            checked={selection === 'manual'}
            onChange={(e) => setSelection(e.target.value)}
            className="mr-2 w-5 h-5"
          />
          Manual
        </label>
        <label className='text-[25px]'>
          <input
            type="radio"
            value="automatic"
            checked={selection === 'automatic'}
            onChange={(e) => setSelection(e.target.value)}
            className="mr-2 w-5 h-5"
          />
          Automatic
        </label>
      </div>

      {/* Time slots input */}
      <div className="max-w-md mx-auto mt-10 p-6 shadow-md rounded-md">
        <h4 className='text-[22px] mb-4'>Select Time Slots (in HH:mm:ss):</h4>
        {timeSlots.map((slot, index) => (
          <div key={index} className="mb-4">
            <label className="block text-gray-700 mb-2">Time Slot {index + 1}</label>
            <div className="flex">
            <input  type="time" step="1"
                placeholder="HH:mm:ss"
                value={slot.startTime}
                onChange={(e) => handleTimeSlotChange(index, 'startTime', e.target.value)}
                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 mr-2"
                required
              />
              <input
                type="time" step="1"
                placeholder="HH:mm:ss"
                value={slot.endTime}
                onChange={(e) => handleTimeSlotChange(index, 'endTime', e.target.value)}
                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                required
              />
              <button
                type="button"
                onClick={() => removeTimeSlot(index)}
                className="ml-2 bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Remove
              </button>
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={addTimeSlot}
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
        >
          Add Time Slot
        </button>
      </div>

      {/* Manual input field */}
      {selection === 'manual' && (
        <div className="max-w-md mx-auto mt-10 p-6 shadow-md rounded-md">
          <form>
            <div className="mb-4">
              <label htmlFor="winningNumber" className="block text-gray-700 mb-3">
                Lottery Number
              </label>
              <input
                type="number"
                id="winningNumber"
                value={winNumber}
                onChange={(e) => setWinNumber(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                required
              />
            </div>

            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              // disabled={loading}
              className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:bg-blue-600"
            >
             Save
            </button>
          </form>
        </div>
      )}

      {/* Automatic difficulty levels */}
      {selection === 'automatic' && (
        <div className="max-w-md mx-auto mt-10 p-6 shadow-md rounded-md">
          <h4 className='text-[22px] mb-4'>Select Difficulty :</h4>
          <div className='flex items-center justify-around'>
            <label className='text-[18px]'>
              <input
                type="radio"
                value="easy"
                checked={difficulty === 'easy'}
                onChange={(e) => setDifficulty(e.target.value)}
                className="mr-2 w-4 h-4"
              />
              Easy
            </label>
            <label className='text-[18px]'>
              <input
                type="radio"
                value="medium"
                checked={difficulty === 'medium'}
                onChange={(e) => setDifficulty(e.target.value)}
                className="mr-2 w-4 h-4"
              />
              Medium
            </label>
            <label className='text-[18px]'>
              <input
                type="radio"
                value="hard"
                checked={difficulty === 'hard'}
                onChange={(e) => setDifficulty(e.target.value)}
                className="mr-2 w-4 h-4"
              />
              Hard
            </label>
          </div>

          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            // disabled={loading}
            className="w-full mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:bg-blue-600"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default LotteryComponent;


import React, { useState } from 'react';
import Layout from '../components/Layout';

import UserLogs from '../components/UserLogs';


function UserLogsPage() {


  return (
    <>
  
    <Layout>
       <UserLogs/>
    </Layout>
    </>
  );
}

export default UserLogsPage;
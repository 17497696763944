import React, { useState } from 'react';
import Layout from '../components/Layout';

import AcceptedWR from '../components/AcceptedWR';


function AcceptedPage() {


  return (
    <>
  
    <Layout>
       <AcceptedWR/>
    </Layout>
    </>
  );
}

export default AcceptedPage;
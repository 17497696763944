import React, { useState } from 'react';
import Layout from '../components/Layout';
import TreeChart from '../components/TreeChart';


function TreeChartPage() {


  return (
    <>
  
    <Layout>
       <TreeChart/>
    </Layout>
    </>
  );
}

export default TreeChartPage;
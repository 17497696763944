import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const baseUrl = 'https://api.rapidxpro.com'


const UserProfile = () => {
  // const [tab, setTab] = useState('bankDetails');
  const [user, setUser] = useState({});
  // const [newPassword, setNewPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  // const [coins, setCoins] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem('authToken'); 
  const Authorization = `Bearer ${token}`;

  useEffect(() => {
    // Fetch user details
    axios.get(`${baseUrl}/api/superAdmin/user/${id}`, {
      headers: { Authorization }
    })
    .then(response =>{
       let data = response.data
       console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
       setUser(response.data)
  })
    .catch(error => console.error('Error fetching user data:', error));
  }, [id, Authorization]);

  // const handleChangePassword = (e) => {
  //   e.preventDefault();
  //   if (newPassword !== confirmPassword) {
  //     alert('New password and confirm password do not match');
  //     return;
  //   }
  //   axios.post(`${baseUrl}/api/superAdmin/resetPassword/${id}`, { newPassword ,confirmPassword}, {
  //     headers: { Authorization }
  //   })
  //   .then(response => 
  //     {
  //     alert('Password changed successfully')
  //     navigate('/users')
  // })
  //   .catch(error => console.error('Error changing password:', error));
  // };

  // const handleAddCoins = (e) => {
  //   e.preventDefault();
  //   axios.post(`${baseUrl}/api/superAdmin/addCoins`, { phoneNumber : user.phoneNumber, coins }, {
  //     headers: { Authorization }
  //   })
  //   .then(response => {
  //     console.log(response)
  //     alert('Coins added successfully')
  //     navigate('/users')
  //   }
  //   )
  //   .catch(error => console.error('Error adding coins:', error));
  // };

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen p-4">
      <div className="bg-white rounded shadow p-6 w-full max-w-4xl">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">User Profiles</h1>
          <Link to='/users'><button className="bg-yellow-500 text-white px-4 py-2 rounded">Back</button></Link>
        </div>

        <div className="flex">
          <div className="w-1/3">
            <div className="flex flex-col items-center">
              <div className="w-24 h-24 bg-gray-300 rounded-full mb-4"></div>
              <h2 className="text-xl font-bold">{user.userName || user.phoneNumber || 'Loading...'}</h2>
              <p className="text-gray-600">{user.email || ''}</p>
              <p className="text-gray-600">{user.phoneNumber || ""}</p>
            </div>

            <div className="bg-white rounded shadow p-4 mt-4">
              <h3 className="text-lg font-bold mb-4">Wallet Details</h3>
              <p>Total Coins: {user.coins || 0}</p>
              <p>Deposit Coins: {user.depositCoins || 0.00}</p>
              <p>Winning Coins: {user.winningCoins || 0.00}</p>
            </div>
          </div>

          <div className="w-2/3 pl-6">
            <div className="flex border-b mb-4">
            <h3 className="text-lg font-bold mb-4">Bank Details</h3>
              {/* <button
                // onClick={() => setTab('bankDetails')}
                //className={`px-4 py-2 ${tab === 'bankDetails' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'}`}
              >
                Bank Details
              </button> */}
              {/* <button
                onClick={() => setTab('changePassword')}
                className={`px-4 py-2 ${tab === 'changePassword' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'}`}
              >
                Change Password
              </button> */}
              {/* <button
                onClick={() => setTab('addCoins')}
                className={`px-4 py-2 ${tab === 'addCoins' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600'}`}
              >
                Add Coins
              </button> */}
            </div>

              <div>
                
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-bold">Bank</h4>
                    <p>{user.bank || 'N/A'}</p>
                  </div>
                  <div>
                    <h4 className="font-bold">Account Holder Name</h4>
                    <p>{user.accountHolderName || 'N/A'}</p>
                  </div>
                  <div>
                    <h4 className="font-bold">Account Number</h4>
                    <p>{user.accountNumber || 'N/A'}</p>
                  </div>
                  <div>
                    <h4 className="font-bold">IFSC</h4>
                    <p>{user.ifsc || 'N/A'}</p>
                  </div>
                  <div>
                    <h4 className="font-bold">UPI ID</h4>
                    <p>{user.upiId || 'N/A'}</p>
                  </div>
                </div>

                <h3 className="text-lg font-bold mt-6 mb-4">Other Details</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-bold">Pan Card</h4>
                    <p>{user.panCard || 'N/A'}</p>
                  </div>
                  <div>
                    <h4 className="font-bold">Aadhar Card</h4>
                    <p>{user.aadharCard || 'N/A'}</p>
                  </div>
                </div>
              </div>
         

            {/* {tab === 'changePassword' && (
              <div>
                <h3 className="text-lg font-bold mb-4">Change Password</h3>
                <form onSubmit={handleChangePassword}>
                  <div className="mb-4">
                    <label className="block mb-2" htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="w-full border px-2 py-1"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2" htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full border px-2 py-1"
                      required
                    />
                  </div>
                  <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Change Password</button>
                </form>
              </div>
            )}

            {tab === 'addCoins' && (
              <div>
                <h3 className="text-lg font-bold mb-4">Add Coins</h3>
                <form onSubmit={handleAddCoins}>
                  <div className="mb-4">
                    <label className="block mb-2" htmlFor="user">User</label>
                    <input
                      type="text"
                      id="user"
                      value={user.userName || user.phoneNumber || 'Loading...'}
                      disabled
                      className="w-full border px-2 py-1"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2" htmlFor="coins">Coins</label>
                    <input
                      type="number"
                      id="coins"
                      value={coins}
                      onChange={(e) => setCoins(e.target.value)}
                      className="w-full border px-2 py-1"
                      placeholder="Coins"
                      required
                    />
                  </div>
                  <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Add Coins</button>
                </form>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

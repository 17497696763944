import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

// 
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    console.log(token)
    if(token){
      navigate('/dashboard')
    }
  },[])


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(email, password)
      const response = await axios.post('https://api.rapidxpro.com/api/superAdmin/login', {
        email,
        password,
      });
      console.log(response)

      // Assuming a successful login returns a token
      const { token } = response.data;

      // Save the token in localStorage or a context
      localStorage.setItem('authToken', token);

      // Redirect to the dashboard
      navigate('/dashboard');
    } catch (error) {
      // Handle login errors
      alert('Invalid email or password');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-200">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 sm:w-96">
        <h2 className="text-xl mb-6 text-center text-gray-500">Sign in to start your session</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6 relative">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Email"
              required
            />
            <i className="fa-solid fa-user absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
          </div>
          <div className="mb-6 relative">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 pl-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Password"
              required
            />
            <i className="fa-solid fa-lock absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Sign In 
          </button>
      
        </form>
      </div>
    </div>
  );
}

export default LoginPage;


import React, { useState } from 'react';
import Layout from '../components/Layout';
import Lobbies from '../components/Lobbies';


function LobbiesPage() {


  return (
    <>
  
    <Layout>
       <Lobbies/>
    </Layout>
    </>
  );
}

export default LobbiesPage;
// import React, { useState } from 'react';
// import Navbar from './Navbar';
// import Sidebar from './Sidebar';



// const Layout = ({ children }) => {
//   const [sidebarOpen, setSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarOpen(!sidebarOpen);
//   };

//   return (
//     <>
//       <Navbar isOpen={sidebarOpen} toggleSidebar={toggleSidebar}/>
//       <div className="flex min-h-screen bg-gray-100"> 
//         <div className={`fixed left-0 top-0 `}>

//         <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
//         </div>
//         <div className=" md:w-[calc(100vw-300px)] w-full   md:ml-[270px] pt-20">
//           {children}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Layout;


import React, { useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <Navbar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex w-full min-h-screen bg-gray-100 overflow-x-hidden"> 
        <div className={`fixed left-0 top-0 `}>
          <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        </div>
        <div className=" w-full md:pl-[270px] pt-20 p-6 overflow-x-hidden">
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const baseUrl = 'https://api.rapidxpro.com'


const EditLobby = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  let token = localStorage.getItem('authToken'); // Changed to 'authToken' for consistency
  let Authorization = `Bearer ${token}`;

  const [formData, setFormData] = useState({
    gameType: "",
    gameMode: "",
    noOfPlayers: "",
    entryFee: "",
    commissionPercentage: "",
    winAmount: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/lobbies/${id}`, {
          headers: {
            'Authorization': Authorization
          }
        });
        const lobbyData = response.data;
        setFormData({
          gameType: lobbyData.gameType,
          gameMode: lobbyData.gameMode,
          noOfPlayers: lobbyData.noOfPlayers,
          entryFee: lobbyData.entryFee,
          commissionPercentage: lobbyData.commissionPercentage,
          winAmount: lobbyData.winAmount,
        });
      } catch (error) {
        console.error('Error fetching lobby data:', error);
      }
    };
    fetchData();
  }, [id, Authorization]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${baseUrl}/api/lobbies/edit/${id}`, formData, {
        headers: {
          'Authorization': Authorization,
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
      alert("Lobby updated successfully, redirecting to all Lobbies!");
      navigate('/lobbies');
    } catch (error) {
      console.error('Error:', error.response?.data?.error);
      alert(error.response?.data?.error || 'An error occurred');
    }
  };

  return (
    <div className="bg-white rounded shadow p-4 w-full">
      <div>
        <h2 className="text-2xl font-bold mb-6">Edit Lobby</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="gameType">Game Type</label>
            <select
              id="gameType"
              name="gameType"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.gameType}
              onChange={handleChange}
            >
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="gameMode">Game Mode</label>
            <select
              id="gameMode"
              name="gameMode"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.gameMode}
              onChange={handleChange}
            >
              <option value="Quick">Quick</option>
              <option value="Timed (600s)">Timed (600s)</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="noOfPlayers">Max Players</label>
            <input
              type="number"
              id="noOfPlayers"
              name="noOfPlayers"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Max Players"
              value={formData.noOfPlayers}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="entryFee">Entry Fee</label>
            <input
              type="number"
              id="entryFee"
              name="entryFee"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Entry Fee"
              value={formData.entryFee}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="commissionPercentage">Commission Percentage</label>
            <input
              type="number"
              id="commissionPercentage"
              name="commissionPercentage"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Commission Percentage"
              value={formData.commissionPercentage}
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 mb-2" htmlFor="winAmount">Rank Wise Win Amount</label>
            <input
              type="number"
              id="winAmount"
              name="winAmount"
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Rank Wise Win Amount"
              value={formData.winAmount}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-end space-x-4">
            <Link to='/lobbies'>
              <button
                type="button"
                className="bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-200"
              >
                Back
              </button>
            </Link>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditLobby;